import React, { useState, useEffect } from 'react';

const InspirationalQuote = () => {
  const [quote, setQuote] = useState('');

  const quotes = [
    "The best way to predict the future is to create it. - Peter Drucker",
    "Success is not the key to happiness. Happiness is the key to success. - Albert Schweitzer",
    "Believe you can and you're halfway there. - Theodore Roosevelt",
    "The only limit to our realization of tomorrow is our doubts of today. - Franklin D. Roosevelt",
    "Your time is limited, don't waste it living someone else's life. - Steve Jobs",
    "The most important point is to accept yourself and stand on your two feet. - Shunryu Suzuki",
    "The only way to do great work is to love what you do. - Steve Jobs",
    "The best preparation for tomorrow is doing your best today. - H. Jackson Brown Jr.",
    "Change is inevitable but personal growth is a choice. - Bob Proctor",
    "People seldom do what they believe in. They do what is convenient, then repent. - Bob Dylan",
    "The only way to achieve the impossible is to believe it is possible. - Charles Kingsleigh",
    "The only person you are destined to become is the person you decide to be. - Ralph Waldo Emerson",
    "Life can be found only in the present moment. The past is gone, the future is not yet here, and if we do not go back to ourselves in the present moment, we cannot be in touch with life. - Thich Nhat Hanh",
    "Success is not final, failure is not fatal: it is the courage to continue that counts. - Winston Churchill",
    "Action is the foundational key to all success. - Pablo Picasso",
    "Focused, hard work is the real key to success. - John Carmack",
    "Work harder on yourself than you do on your job. - Jim Rohn",
    "The only limit to our realization of tomorrow is our doubts of today. - Franklin D. Roosevelt",
    "If you don't find the time, if you don't do the work, you don't get the results. - Arnold Schwarzenegger",
  ];

  useEffect(() => {
    const getRandomQuote = () => {
      const randomIndex = Math.floor(Math.random() * quotes.length);
      setQuote(quotes[randomIndex]);
    };

    // Display a random quote on initial load
    getRandomQuote();

    const quoteInterval = setInterval(() => {
      getRandomQuote();
    }, 5000); // Change quote every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(quoteInterval);
  }, [quotes]);

  return (
    <div aria-live="polite" className="mt-4">
      <blockquote className="blockquote text-center border border-white rounded p-4 bg-dark text-white shadow-sm">
        <p className="mb-0 font-italic">{quote}</p>
      </blockquote>
    </div>
  );
};

export default InspirationalQuote;
