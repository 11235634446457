import React, { useState } from 'react';

const AnswerEditor = ({ answer, onAnswerChange, label }) => {
  const [content, setContent] = useState(answer.content);
  const [is_correct, setIsCorrect] = useState(answer.is_correct);

  const handleContentChange = (e) => {
    const newContent = e.target.value;
    setContent(newContent);
    onAnswerChange(answer.id, { content: newContent, is_correct });
  };

  const handleCorrectChange = (e) => {
    const newCorrect = e.target.checked;
    setIsCorrect(newCorrect);
    onAnswerChange(answer.id, { content, is_correct: newCorrect });
  };

  return (
    <div className="answer-editor d-flex align-items-center">
      <strong className="mr-2">{label}.</strong>
      <input
        type="text"
        value={content}
        onChange={handleContentChange}
        className="form-control mx-2 bg-black text-white"
      />
      <div className="form-check">
        <input
          type="checkbox"
          checked={is_correct}
          onChange={handleCorrectChange}
          className="form-check-input"
        />
        <label className="form-check-label">Correct</label>
      </div>
    </div>
  );
};

export default AnswerEditor;
