import React, { useState, useEffect } from 'react';
import InspirationalQuote from './InspirationalQuote';

const QuizProgress = ({ quizGeneratorId, initialStatus, initialProgress }) => {
  const [progress, setProgress] = useState(initialProgress || 0);
  const [status, setStatus] = useState(initialStatus || 'in_progress');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    let interval;

    const fetchProgress = () => {
      fetch(`/quiz_generators/${quizGeneratorId}/progress.json`, {
        headers: { 'Cache-Control': 'no-cache' },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          return response.json();
        })
        .then(data => {
          console.log('Fetched progress:', data.progress);
          setProgress(data.progress);
          setStatus(data.status);

          if (data.status === 'completed') {
            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            } else {
              setErrorMessage('Quiz generation completed, but no redirect URL found.');
              clearInterval(interval);
            }
          } else if (data.status === 'failed') {
            setErrorMessage('Quiz generation failed. Please try again.');
            clearInterval(interval);
          }
        })
        .catch(error => {
          console.error('Error fetching progress:', error);
          setErrorMessage('An error occurred while fetching progress.');
          clearInterval(interval);
        });
    };

    interval = setInterval(fetchProgress, 1000);

    return () => clearInterval(interval);
  }, [quizGeneratorId]);

  return (
    <div className="container my-4">
      <div className="text-center">
        {status === 'in_progress' && (
          <div>
            <i className="fas fa-spinner fa-3x fa-spin mb-3 text-primary"></i>
            <h1>Generating Your Quiz...</h1>
            <p>Please wait while we prepare your quiz.</p>
          </div>
        )}
        {status === 'completed' && (
          <div>
            <i className="fas fa-check-circle fa-3x mb-3 text-success"></i>
            <h1>Your Quiz is Ready!</h1>
            <p>Redirecting you now...</p>
          </div>
        )}
        {status === 'failed' && (
          <div>
            <i className="fas fa-exclamation-circle fa-3x mb-3 text-danger"></i>
            <h1>Quiz Generation Failed</h1>
            <p>{errorMessage}</p>
          </div>
        )}
      </div>

      {status === 'in_progress' && (
        <div>
          <div className="progress my-4">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-success"
              role="progressbar"
              style={{ width: `${progress}%` }}
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {Math.floor(progress)}%
            </div>
          </div>
          <InspirationalQuote />
        </div>
      )}

      {errorMessage && status !== 'failed' && (
        <div className="alert alert-danger mt-3" role="alert">
          <h4 className="alert-heading">Oops!</h4>
          <p>{errorMessage}</p>
          <hr />
          <p className="mb-0">Please try again or contact support if the problem persists.</p>
        </div>
      )}
    </div>
  );
};

export default QuizProgress;
