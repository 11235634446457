import React, { useState } from 'react';
import QuestionEditor from './QuestionEditor';

const QuizEditor = ({ quiz, quizID }) => {
  const [showAnswer, setShowAnswer] = useState(false); // State to control answer visibility
  const [status, setStatus] = useState(quiz.status); // State to manage the quiz's status
  const [isToggling, setIsToggling] = useState(false); // State to manage the toggling process

  const handleToggleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  const renderStatusIcon = () => {
    if (quiz.status === 'hidden') {
      return (
        <span className="text-muted">
          <i className="fas fa-eye-slash mr-2"></i> Private
        </span>
      );
    }
    return (
      <span className="text-success">
        <i className="fas fa-eye mr-2"></i> Public
      </span>
    );
  };

  return (
    <div className="quiz-editor container mt-5">
      <h2>{quiz.title} - {renderStatusIcon()}</h2>
      <div className="d-flex align-items-center justify-content-start mb-3">
        <label className="mx-2 text-white">Show answer</label>
        <input
          type="checkbox"
          checked={showAnswer}
          onChange={handleToggleShowAnswer}
          className="form-check-input"
        />
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
        <a href={`/quizzes/${quizID}`} className="btn btn-success">
        <i className="fa-solid fa-chalkboard-user"></i> Study Mode
        </a>
        <a href={`/quizzes/${quizID}/edit`} className="btn btn-outline-info">
          <i className="fa-solid fa-pen-to-square"></i> Edit Quiz
        </a>
      </div>
      {quiz.questions.map((question) => (
        <QuestionEditor key={question.id} question={question} showAnswer={showAnswer} />
      ))}
    </div>
  );
};

export default QuizEditor;
